// https://devhints.io/wip/intl-datetime
const DATE_TIME_FORMAT = Intl.DateTimeFormat("zh", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  timeZoneName: "short",
});

export const formatDateTime = (railsDateTime) => {
  if (!railsDateTime) {
    return "";
  }

  let date = new Date(railsDateTime);
  return DATE_TIME_FORMAT.format(date);
};
