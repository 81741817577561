<template>
  <div>
    <h2>Product Idea</h2>
    <BlockUI :blocked="displayImportDialog">
      <DataTable
        :value="state.data"
        :paginator="true"
        class="p-datatable-mkls"
        :rows="10"
        dataKey="id"
        :rowHover="true"
        :loading="loading"
        responsiveLayout="scroll"
        :globalFilterFields="['keyword_en', 'keyword_zh']"
        v-model:filters="filters"
      >
        <!-- Header -->
        <template #header>
          <div
            class="flex md:flex-row md:justify-content-between md:justify-content-center md:align-items-center"
          >
            <Button
              type="button"
              icon="pi pi-plus"
              label="Import"
              class="p-button-outlined"
              @click="showImportModal()"
            />

            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Keyword Search"
              />
            </span>
          </div>
        </template>

        <!-- No Data -->
        <template #empty>
          <div class="flex md:justify-content-center md:align-items-center">
            <span>No data.</span>
          </div>
        </template>

        <Column field="created_at" header="Created Time">
          <template #body="{ data }">
            {{ formatDateTime(data.created_at) }}
          </template>
        </Column>
        <Column field="keyword_en" header="Keyword(EN)"></Column>
        <Column field="keyword_zh" header="Keyword(ZH)"></Column>
        <Column field="region" header="Region">
          <template #body="{ data }">
            {{ readableAmzRegion(data.region) }}
          </template>
        </Column>
        <Column field="sheet_link" header="Google Sheet">
          <template #body="{ data }">
            <a
              :href="data.sheet_link"
              target="_blank"
              rel="noreferrer noopener"
            >
              Open
            </a>
          </template>
        </Column>
        <Column field="username" header="Username"></Column>
      </DataTable>
    </BlockUI>

    <ProductIdeaImportDialog
      v-model:visible="displayImportDialog"
      @confirm="loadData()"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import { FilterMatchMode } from "primevue/api";

import MklData from "@/services/mkl_data";
import { formatDateTime } from "@/utils/common_helper";
import { readableAmzRegion } from "@/utils/mkw_helper";
import ProductIdeaImportDialog from "@/components/research/ProductIdeaImportDialog";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Column,
    DataTable,
    InputText,
    ProductIdeaImportDialog,
  },
  setup() {
    onMounted(() => {
      loadData();
    });

    const toast = useToast();
    const loading = ref(true);
    const state = reactive({
      data: null,
    });
    const filters = ref({
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
    });
    const displayImportDialog = ref(false);

    const loadData = () => {
      MklData.list()
        .then(({ data }) => {
          state.data = data.mkls;
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Error Message",
            detail: "Can not load MKL Data",
            life: 3000,
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const showImportModal = () => {
      displayImportDialog.value = true;
    };

    return {
      loading,
      state,
      filters,
      displayImportDialog,

      loadData,
      showImportModal,

      formatDateTime,
      readableAmzRegion,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep(.p-datatable) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }
}
</style>
