<template>
  <Dialog
    v-model:visible="visibleCom"
    @show="init()"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }"
  >
    <template #header>
      <h3>Import Product Idea</h3>
    </template>

    <h5>Google Sheet URL</h5>
    <span class="p-float-label">
      <InputText
        v-model="googleSheetUrl"
        id="googleSheetUrl"
        width="100%"
        placeholder="Google Sheet URL"
        :disabled="loading"
      />
    </span>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        @click="handleCancel()"
        :disabled="loading"
      />
      <Button
        label="Import"
        icon="pi pi-check"
        autofocus
        @click="handleConfirm()"
        :disabled="loading"
      />
    </template>
  </Dialog>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";

import MklData from "@/services/mkl_data";

export default defineComponent({
  components: {
    Button,
    Dialog,
    InputText,
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
    },
  },
  emits: ["confirm"],
  setup(props, { emit }) {
    const visibleCom = computed({
      get: () => props.visible,
      set: (val) => {
        emit("update:visible", val);
      },
    });

    const toast = useToast();
    const loading = ref(false);
    const googleSheetUrl = ref("");

    const init = () => {
      googleSheetUrl.value = "";
    };

    const handleCancel = () => {
      emit("update:visible", false);
    };

    const handleConfirm = () => {
      loading.value = true;

      MklData.import(googleSheetUrl.value)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Error Message",
              detail: "Can not import MKL Data",
              life: 3000,
            });
          } else {
            toast.add({
              severity: "success",
              summary: "Import Success",
              detail: "Import MKL Data successfully",
              life: 3000,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Error Message",
            detail: "Can not import MKL Data",
            life: 3000,
          });
        })
        .finally(() => {
          emit("confirm");
          emit("update:visible", false);
          loading.value = false;
        });
    };

    return {
      visibleCom,
      loading,
      googleSheetUrl,

      init,
      handleCancel,
      handleConfirm,
    };
  },
});
</script>

<style lang="scss" scoped>
.p-inputtext {
  width: 100%;
}
</style>
