import { axiosApiInstance } from "@/services/base";

class MklData {
  list() {
    return axiosApiInstance.get("/mkls");
  }
  get(id) {
    return axiosApiInstance.get(`/mkl/${id}`);
  }
  import(spreadsheet_url) {
    return axiosApiInstance.post("/mkls/import", {
      spreadsheet_url: spreadsheet_url,
    });
  }
}

export default new MklData();
